import {Location, parsePath} from 'history'
import type {To} from 'history'
import * as React from 'react'

import {matchRoutes} from 'react-router'

import {
  fetchCurrentUserData,
  fetchIsBranchPresent,
  fetchPoolPermissions,
  fetchTabs,
  subscribeOnRemainingAgents,
} from '../../actions'
import {fetchBuildsData, fetchHasBuilds} from '../../actions/builds'
import {fetchProjectWithAllParentsData, fetchSingleProjectData} from '../../actions/projects'
import {subscribeOnBuildTypeStatus} from '../../actions/statuses'
import type {ActionCreator, AppThunk} from '../../actions/types'
import {getEvents} from '../../containers/BuildsFetcher'
import {getChangesArg} from '../../rest/arguments'
import {getEndpointForBranches} from '../../rest/branches'
import {getSingleBuildArg} from '../../rest/builds'
import {getBuildTypeArg} from '../../rest/buildTypes'
import {getBuildLocator} from '../../rest/locators'
import Routes, {getBaseRoute} from '../../routes'
import {
  createBuildTypeNode,
  getBuildsInited,
  getBuildType,
  getCurrentUserId,
  getCurrentUserLoaded,
  getHasBuildsInited,
  getIsAllTabsInited,
  getIsBranchPresentInited,
  getLocatorIfReady,
  getProject,
  getProjectRequest,
  getTabsFetchable,
  isBuildTypeLoaded,
} from '../../selectors'
import {restApi} from '../../services/rest'
import store from '../../store'
import {
  AGENTS_TAB_PARAMS_KEY,
  BuildTypeId,
  BuildPageTabNamesEnum,
  BuildTypePageTabNamesEnum,
  ProjectPageTabNamesEnum,
  ProjectId,
  ROOT_PROJECT_ID,
  toAgentId,
  toAgentPoolId,
  toBuildId,
  toBuildTypeId,
  toProjectId,
  BuildTypeInternalId,
  BuildId,
  stringifyId,
  toChangeId,
  STARRED_LOCATOR,
  ProjectInternalId,
} from '../../types'
import {internalProps} from '../../types/BS_types'
import {parseBranch} from '../../utils/branchNames'
import {emptyArray} from '../../utils/empty'
import {notNull} from '../../utils/guards'
import type {KeyValue} from '../../utils/object'
import {getPermalinkLocator} from '../../utils/permalinks'
import {queryToObject} from '../../utils/queryParams'
import type {QueryParams} from '../../utils/queryParams'
import {
  getBuildTypeSuffix,
  getProjectSuffix,
  getTopic,
  getUserSuffix,
  subscribeOnBuildTypeEvents,
  subscribeOnOverallEvents,
} from '../../utils/subscriber'
import {
  BUILD_CHANGES_LOADED,
  BUILD_FINISHED,
  BUILD_INTERRUPTED,
  BUILD_TYPE_REMOVED_FROM_QUEUE,
  BUILD_TYPE_UPDATED,
  CHANGE_ADDED,
  PROJECT_UPDATED,
  USER_PERMISSIONS_CHANGED,
} from '../../utils/subscriptionEvents'
import {getTabParamsKey} from '../../utils/tabs'
import AgentsScreen from '../AgentsScreen/AgentsScreen'
import {
  subscribeOnAgent,
  subscribeOnAgentPools,
  subscribeOnAgents,
} from '../AgentsScreen/AgentsScreen.actions'
import {fetchChangeStatus} from '../common/Changes/Changes.actions'
import {submitPager} from '../common/Pager/Pager.actions.base'
import {getPager} from '../common/Pager/Pager.selectors'
import {PagerGroup} from '../common/Pager/Pager.types'
import {SnapshotDependenciesModes} from '../pages/BuildPage/DependenciesTab/DependenciesTab.modes'
import {getSnapshotDependenciesLocator} from '../pages/BuildPage/DependenciesTab/DependenciesTab.utils'
import {mapStateToBuildTypeHistoryProps} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.selectors'
import {Modes as OverviewBuildTypeModes} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'
import {fetchCompareBuildsList} from '../pages/CompareBuildsPage/CompareBuildsPage.actions'
import {pipelinesEnabled} from '../pages/PipelinesPages/utils/featureToggles'
import {getBuildTypeLineLocator} from '../pages/ProjectPage/ProjectOverviewTab/BuildsByBuildType/BuildTypeLine/BuildTypeLine.selectors'
import {Modes as OverviewProjectModes} from '../pages/ProjectPage/ProjectOverviewTab/NestedProjectTrendsOrBuildsOverview.modes'
import {getQueuePageLocator} from '../pages/QueuePage/QueuePage.utils'

import {getIsAvailabilityError} from './App.selectors'
import {subscribeOnPoolCounters} from './QueueSidebar/QueueSidebar.actions'

const AgentsOverviewPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentsOverviewPage", webpackPrefetch: true */
      '../AgentsScreen/AgentsOverviewPage/AgentsOverviewPage'
    ),
)
const UnauthorizedAgentsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UnauthorizedAgentsPage", webpackPrefetch: true */
      '../AgentsScreen/UnauthorizedAgentsPage/UnauthorizedAgentsPage'
    ),
)
const AgentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentPage", webpackPrefetch: true */
      '../AgentsScreen/AgentPage/AgentPage.container'
    ),
)
const AgentPoolPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AgentPoolPage", webpackPrefetch: true */
      '../AgentsScreen/AgentPoolPage/AgentPoolPage'
    ),
)
const CloudImagePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CloudImagePage", webpackPrefetch: true */
      '../AgentsScreen/CloudImagePage/CloudImagePage'
    ),
)
const ProjectPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectPage", webpackPrefetch: true */
      '../pages/ProjectPage/ProjectPage.container'
    ),
)
const FavoriteProjectsPage: React.ComponentType = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FavoriteProjectsPage", webpackPrefetch: true */
      '../pages/FavoriteProjectsPage/FavoriteProjectsPage.container'
    ),
)
const FavoriteBuildsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FavoriteBuildsPage", webpackPrefetch: true */
      '../pages/FavoriteBuildsPage/FavoriteBuildsPage'
    ),
)
const BuildTypePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildTypePage", webpackPrefetch: true */
      '../pages/BuildTypePage/BuildTypePage'
    ),
)
const BuildPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BuildPage", webpackPrefetch: true */
      '../pages/BuildPage/BuildPage.container'
    ),
)
const CompareBuildsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CompareBuildsPage", webpackPrefetch: true */
      '../pages/CompareBuildsPage/CompareBuildsPage.container'
    ),
)
const GuidesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "GuidesPage", webpackPrefetch: true */
      '../pages/GuidesPage/GuidesPage'
    ),
)
const TestHistoryPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TestHistoryPage", webpackPrefetch: true */
      '../pages/TestHistoryPage/TestHistoryPage.container'
    ),
)
const QueuePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "QueuePage", webpackPrefetch: true */
      '../pages/QueuePage/QueuePage'
    ),
)
const ChangePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePage", webpackPrefetch: true */
      '../pages/ChangePage/ChangePage'
    ),
)
const ChangesPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangesPage", webpackPrefetch: true */
      '../pages/ChangesPage/ChangesPage'
    ),
)
const PipelinesPages = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PipelinesPages", webpackPrefetch: true */
      '../pages/PipelinesPages/PipelinesPages'
    ),
)
type PreloadParams = {
  readonly params: KeyValue<string, string>
  readonly queryParams: QueryParams
  readonly prevParams: KeyValue<string, string> | null | undefined
  readonly prevQueryParams: QueryParams | null | undefined
  readonly path: string
  readonly context: Symbol
}
type PreloadableRoute = {
  readonly path: string
  // flowlint-next-line unclear-type:off
  readonly Component: React.ComponentType<any>
  readonly preload?: ActionCreator<PreloadParams>
}
type RedirectType = {
  readonly from: string
  readonly to: string
}
const USE_NEW_TEST_HISTORY_PAGE: boolean = internalProps['teamcity.ui.newTestHistoryPage']
const USE_NEW_CHANGE_PAGE: boolean = internalProps['teamcity.ui.newChangePage']
const USE_NEW_CHANGES_PAGE: boolean = internalProps['teamcity.ui.newChangesPage']
const USE_NEW_QUEUE_PAGE: boolean = internalProps['teamcity.ui.sakuraQueuePage.enabled']

type Subscriptions = {
  previous: Record<string, () => void>
  current: Record<string, () => void>
  currentContext: Symbol | null
  add(context: Symbol, key: string, subscribe: () => () => void): () => void
  clear(context: Symbol, isInterrupt?: boolean): void
}

const subscriptions: Subscriptions = {
  previous: {},
  current: {},
  currentContext: null,
  add(context, key, subscribe) {
    if (context !== this.currentContext) {
      return () => {}
    }
    if (this.current[key]) {
      throw new Error(
        `Subscription keys for preloaded route data must be unique, got duplicate key ${key}`,
      )
    }
    if (this.previous[key]) {
      this.current[key] = this.previous[key]
      delete this.previous[key]
    } else {
      this.current[key] = subscribe()
    }
    return this.current[key]
  },
  clear(context, isInterrupt) {
    if (context !== this.currentContext) {
      return
    }
    if (isInterrupt) {
      this.previous = {...this.previous, ...this.current}
    } else {
      Object.values(this.previous).forEach(fn => fn())
      this.previous = this.current
    }
    this.current = {}
    this.currentContext = null
  },
}

const preloadAgentData =
  ({context}: PreloadParams): AppThunk =>
  dispatch => {
    subscriptions.add(context, 'agents', () => dispatch(subscribeOnAgents()))
    subscriptions.add(context, 'agentPools', () => dispatch(subscribeOnAgentPools()))

    dispatch(fetchPoolPermissions(true))
  }

const preloadLicensingData =
  (context: Symbol): AppThunk =>
  dispatch =>
    subscriptions.add(context, 'licensingData', () => dispatch(subscribeOnRemainingAgents()))

const getProjectInternalId =
  (projectId: ProjectId, context: Symbol): AppThunk<Promise<ProjectInternalId | undefined>> =>
  async (dispatch, getState) => {
    const internalIdArg = {projectLocator: `id:${projectId}`, fields: 'internalId'}
    const internalIdSubscription = dispatch(restApi.endpoints.getProject.initiate(internalIdArg))
    subscriptions.add(
      context,
      `projectInternalIdCache:${projectId}`,
      () => internalIdSubscription.unsubscribe,
    )
    await internalIdSubscription
    const {data} = restApi.endpoints.getProject.select(internalIdArg)(getState())
    return data?.internalId
  }

const getBuildTypeInternalId =
  (buildTypeId: BuildTypeId, context: Symbol): AppThunk<Promise<BuildTypeInternalId | undefined>> =>
  async (dispatch, getState) => {
    const internalIdArg = {btLocator: `id:${buildTypeId}`, fields: 'internalId'}
    const internalIdSubscription = dispatch(restApi.endpoints.getBuildType.initiate(internalIdArg))
    subscriptions.add(
      context,
      `buildTypeInternalIdCache:${buildTypeId}`,
      () => internalIdSubscription.unsubscribe,
    )
    await internalIdSubscription
    const {data} = restApi.endpoints.getBuildType.select(internalIdArg)(getState())
    return data?.internalId
  }

const preloadProjectData =
  (context: Symbol, projectId: ProjectId, queryParams: QueryParams): AppThunk =>
  async (dispatch, getState) => {
    const {
      projectTab = ProjectPageTabNamesEnum.OVERVIEW,
      mode = OverviewProjectModes.BUILDS,
      branch,
    } = queryParams
    const internalId = await dispatch(getProjectInternalId(projectId, context))
    if (!getCurrentUserLoaded(getState())) {
      await fetchCurrentUserData()
    }
    const myId = getCurrentUserId(getState())
    let request
    if (internalId != null && myId != null) {
      subscriptions.add(context, `project:${projectId}`, () =>
        subscribeOnOverallEvents(
          [
            getTopic(PROJECT_UPDATED, getProjectSuffix(internalId)),
            getTopic(USER_PERMISSIONS_CHANGED, getUserSuffix(myId)),
          ],
          () => {
            request = dispatch(fetchSingleProjectData(projectId))
            dispatch(fetchProjectWithAllParentsData(projectId))
          },
        ),
      )
    }

    if (projectTab === ProjectPageTabNamesEnum.OVERVIEW && mode === OverviewProjectModes.BUILDS) {
      let project = getProject(getState(), projectId)
      if (project == null) {
        await (request ?? getProjectRequest(getState(), projectId))
        project = getProject(getState(), projectId)
      }
      const buildTypeIds = project?.buildTypes?.buildType ?? emptyArray

      for (const buildTypeId of buildTypeIds) {
        const locator = getBuildTypeLineLocator(getState(), {
          buildTypeId,
          branch: parseBranch(branch),
        })

        if (locator != null && !getBuildsInited(getState(), locator)) {
          dispatch(
            fetchBuildsData({
              locator,
              withPager: false,
              requestOptions: {
                withBuildTypeDetails: true,
                withSnapshotDependencies: false,
                withQueuedInfo: true,
                withRunningInfo: true,
                essential: true,
              },
            }),
          )
        }
      }
    }
  }

const preloadBuildTypeData =
  (
    context: Symbol,
    buildTypeId: BuildTypeId,
    internalId: BuildTypeInternalId | null | undefined,
  ): AppThunk =>
  async (dispatch, getState) => {
    let request
    if (!getCurrentUserLoaded(getState())) {
      await fetchCurrentUserData()
    }
    const myId = getCurrentUserId(getState())
    const buildTypeArg = getBuildTypeArg(buildTypeId)
    if (internalId != null && myId != null) {
      request = new Promise(resolve =>
        subscriptions.add(context, `buildType:${buildTypeId}`, () => {
          let result:
            | ReturnType<ReturnType<typeof restApi.endpoints.getBuildTypeNormalized.initiate>>
            | undefined
          const unsubscribeTopic = subscribeOnOverallEvents(
            [
              getTopic(BUILD_TYPE_UPDATED, getBuildTypeSuffix(internalId)),
              getTopic(USER_PERMISSIONS_CHANGED, getUserSuffix(myId)),
            ],
            () => {
              if (result == null) {
                result = dispatch(restApi.endpoints.getBuildTypeNormalized.initiate(buildTypeArg))
                resolve(result)
              } else {
                result.refetch()
              }
            },
          )
          return () => {
            unsubscribeTopic()
            result?.unsubscribe()
          }
        }),
      )
    }

    if (!isBuildTypeLoaded(getState(), buildTypeId)) {
      await (restApi.util.getRunningOperationPromise('getBuildTypeNormalized', buildTypeArg) ??
        request)
    }

    const buildType = getBuildType(getState(), buildTypeId)

    if (buildType != null) {
      dispatch(
        fetchProjectWithAllParentsData(buildType.projectId, {
          essential: true,
        }),
      )
    }
  }

const preloadTabs =
  (tabParamsKey: string, cacheTabs = true): AppThunk =>
  (dispatch, getState) => {
    if (!getIsAllTabsInited(getState(), tabParamsKey)) {
      dispatch(fetchTabs(tabParamsKey, {essential: true}, cacheTabs))
    }
  }

export const redirects: ReadonlyArray<RedirectType> = [
  {
    from: Routes.DEPRICATED_FAVORITE_BUILDS,
    to: Routes.FAVORITE_BUILDS,
  },
  {
    from: Routes.DEPRICATED_FAVORITE_PROJECTS,
    to: Routes.FAVORITE_PROJECTS,
  },
  {
    from: '*',
    to: Routes.FAVORITE_PROJECTS,
  },
]

const favoriteProjectsRoute: PreloadableRoute = {
  path: Routes.FAVORITE_PROJECTS,
  Component: FavoriteProjectsPage,
  preload: ({queryParams, context}) => preloadProjectData(context, ROOT_PROJECT_ID, queryParams),
}

// https://reactjs.org/blog/2019/11/06/building-great-user-experiences-with-concurrent-mode-and-suspense.html#fetch-in-event-handlers
export const routes: ReadonlyArray<PreloadableRoute> = [
  favoriteProjectsRoute,
  {
    path: Routes.AGENTS,
    Component: AgentsScreen,
    preload: preloadAgentData,
  },
  {
    path: Routes.AGENTS_OVERVIEW,
    Component: AgentsOverviewPage,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadTabs(AGENTS_TAB_PARAMS_KEY))
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.DISCONNECTED_AGENTS_OVERVIEW,
    Component: AgentsOverviewPage,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadTabs(AGENTS_TAB_PARAMS_KEY))
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.AGENTS_UNAUTHORIZED,
    Component: UnauthorizedAgentsPage,
    preload:
      (params: PreloadParams): AppThunk =>
      dispatch => {
        dispatch(preloadAgentData(params))
        dispatch(preloadLicensingData(params.context))
      },
  },
  {
    path: Routes.AGENT,
    Component: AgentPage,
    preload:
      (preloadParams: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        const {params, path, context} = preloadParams
        if (!getIsAvailabilityError(getState(), path)) {
          const agentId = toAgentId(params.agentId ?? 0)
          subscriptions.add(context, `agent:${agentId}`, () =>
            dispatch(
              subscribeOnAgent(agentId, {
                cloudInfo: true,
                tabs: true,
              }),
            ),
          )
        }

        dispatch(preloadAgentData(preloadParams))
        dispatch(preloadLicensingData(context))
      },
  },
  {
    path: Routes.AGENT_POOL,
    Component: AgentPoolPage,
    preload: preloadAgentData,
  },
  {
    path: Routes.CLOUD_IMAGE,
    Component: CloudImagePage,
    preload: preloadAgentData,
  },
  {
    path: Routes.PROJECT,
    Component: ProjectPage,
    preload:
      ({params, queryParams, path, context}: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        if (getIsAvailabilityError(getState(), path)) {
          return null
        }

        const projectId = toProjectId(params.projectId ?? '')
        dispatch(preloadTabs(getTabParamsKey({projectId})))
        return dispatch(preloadProjectData(context, projectId, queryParams))
      },
  },
  {
    path: Routes.FAVORITE_BUILDS,
    Component: FavoriteBuildsPage,
    preload:
      ({context}: PreloadParams): AppThunk =>
      (dispatch, getState) => {
        const fetch = (locator: string | null | undefined) => {
          if (locator != null) {
            dispatch(
              fetchBuildsData({
                locator,
                withPager: true,
                requestOptions: {
                  withBuildTypeDetails: true,
                  withSnapshotDependencies: false,
                  withQueuedInfo: true,
                  withRunningInfo: true,
                  essential: !getBuildsInited(getState(), locator),
                },
              }),
            )
          }
        }

        const getLocator = () =>
          getLocatorIfReady(getState(), {
            withRunningAndQueued: true,
            baseLocator: STARRED_LOCATOR,
          })

        subscriptions.add(context, `favoriteBuildsLocator`, () => {
          let prevLocator = getLocator()
          return store.subscribe(() => {
            const locator = getLocator()
            if (locator !== prevLocator) {
              prevLocator = locator
              fetch(locator)
            }
          })
        })
      },
  },
  {
    path: Routes.BUILD_TYPE,
    Component: BuildTypePage,
    preload:
      ({params, queryParams, path, context}: PreloadParams): AppThunk =>
      async (dispatch, getState) => {
        const {
          buildTypeTab = BuildTypePageTabNamesEnum.OVERVIEW,
          mode = OverviewBuildTypeModes.BUILDS,
          branch,
        } = queryParams
        const state = getState()

        if (getIsAvailabilityError(state, path)) {
          return
        }

        const pageSize = 50

        if (getPager(state, PagerGroup.BUILD).pageSize !== pageSize) {
          dispatch(
            submitPager({
              pageSize,
            }),
          )
        }

        const buildTypeId = toBuildTypeId(params.buildTypeId ?? '')

        const internalId = await dispatch(getBuildTypeInternalId(buildTypeId, context))

        dispatch(preloadBuildTypeData(context, buildTypeId, internalId))

        if (internalId != null) {
          const tabParamsKey = getTabParamsKey({buildTypeId, branch: parseBranch(branch)})
          subscriptions.add(context, `buildTypeTabs:${buildTypeId}:${branch}`, () =>
            subscribeOnBuildTypeEvents(
              internalId,
              [BUILD_CHANGES_LOADED, CHANGE_ADDED, BUILD_INTERRUPTED],
              () => {
                const tabsLoading = getTabsFetchable(getState(), tabParamsKey).loading
                if (!tabsLoading) {
                  dispatch(fetchTabs(tabParamsKey))
                }
              },
            ),
          )
          subscriptions.add(context, `buildTypeStatus:${buildTypeId}:${branch}`, () =>
            dispatch(subscribeOnBuildTypeStatus(buildTypeId, internalId, parseBranch(branch), 0)),
          )
        }

        if (
          buildTypeTab === BuildTypePageTabNamesEnum.OVERVIEW &&
          mode === OverviewBuildTypeModes.BUILDS
        ) {
          const getProps = () =>
            mapStateToBuildTypeHistoryProps(getState(), {
              buildTypeId,
              branch: parseBranch(branch),
              withCollapsedQueued: true,
              withRunningAndQueued: true,
            })
          const {monitorLocator} = getProps()
          const nonDefaultBranchEndpoint = getEndpointForBranches({
            node: createBuildTypeNode(buildTypeId),
            excludeGroups: true,
            excludeDefault: true,
          }).presence

          if (!getIsBranchPresentInited(state, nonDefaultBranchEndpoint)) {
            dispatch(
              fetchIsBranchPresent(nonDefaultBranchEndpoint, {
                essential: true,
              }),
            )
          }

          if (!getHasBuildsInited(state, monitorLocator)) {
            dispatch(
              fetchHasBuilds(monitorLocator, {
                essential: true,
              }),
            )
          }
          const fetch = (locator: string | null | undefined) => {
            if (locator != null) {
              dispatch(
                fetchBuildsData({
                  locator,
                  withPager: true,
                  requestOptions: {
                    withBuildTypeDetails: true,
                    withSnapshotDependencies: false,
                    withQueuedInfo: true,
                    withRunningInfo: true,
                    essential: !getBuildsInited(state, locator),
                  },
                }),
              )
            }
          }

          subscriptions.add(context, `buildTypeBuildLocator:${buildTypeId}:${branch}`, () => {
            let prevLocator = getProps().locator
            return store.subscribe(() => {
              const {locator} = getProps()
              if (locator !== prevLocator) {
                prevLocator = locator
                fetch(locator)
              }
            })
          })

          if (internalId != null) {
            subscriptions.add(context, `buildTypeBuilds:${buildTypeId}:${branch}`, () =>
              subscribeOnBuildTypeEvents(internalId, getEvents(true, true), () =>
                fetch(getProps().locator),
              ),
            )
          }
        }
      },
  },
  {
    path: Routes.BUILD,
    Component: BuildPage,
    preload:
      ({
        params,
        queryParams,
        prevParams,
        prevQueryParams,
        path,
        context,
      }: PreloadParams): AppThunk =>
      async (dispatch, getState) => {
        if (getIsAvailabilityError(getState(), path)) {
          return
        }

        const {
          buildTab = BuildPageTabNamesEnum.OVERVIEW,
          mode = SnapshotDependenciesModes.TIMELINE,
          branch,
        } = queryParams
        const buildTypeId = toBuildTypeId(params.buildTypeId ?? '')
        const prevBuildTypeId = toBuildTypeId(prevParams?.buildTypeId ?? '')
        let buildId: BuildId | null | undefined
        const permalinkLocator = getPermalinkLocator(
          params.buildId ?? '',
          buildTypeId,
          parseBranch(branch),
        )

        if (permalinkLocator != null) {
          if (
            params.buildId !== prevParams?.buildId ||
            buildTypeId !== prevBuildTypeId ||
            branch !== prevQueryParams?.branch
          ) {
            const key = `fetchPermalinkBuild:${permalinkLocator}`
            const isSubscribed = subscriptions.previous[key] != null
            const result = dispatch(
              restApi.endpoints.getBuildNormalizedAsList.initiate(
                getSingleBuildArg(permalinkLocator, {
                  withBuildTypeDetails: true,
                }),
                {subscribe: !isSubscribed},
              ),
            )
            subscriptions.add(context, key, () => result.unsubscribe)
            const {data} = await result
            buildId = data?.result[0]
          }
        } else {
          buildId = toBuildId(params.buildId ?? 0)
        }

        const state = getState()

        if (buildTab === BuildPageTabNamesEnum.DEPENDENCIES) {
          const dependenciesLocator = getLocatorIfReady(state, {
            baseLocator: getSnapshotDependenciesLocator(buildId, mode),
            withRunningAndQueued: true,
          })

          if (dependenciesLocator != null && !getBuildsInited(state, dependenciesLocator)) {
            dispatch(
              fetchBuildsData({
                locator: dependenciesLocator,
                requestOptions: {
                  withBuildTypeDetails: true,
                  withSnapshotDependencies: true,
                  withQueuedInfo: true,
                  withRunningInfo: true,
                  essential: true,
                },
              }),
            )
          }
        }

        const internalId = await dispatch(getBuildTypeInternalId(buildTypeId, context))

        if (internalId != null) {
          const tabParamsKey = getTabParamsKey({buildId})
          subscriptions.add(context, `buildTabs:${buildId}`, () =>
            subscribeOnBuildTypeEvents(
              internalId,
              [BUILD_FINISHED, BUILD_INTERRUPTED, BUILD_TYPE_REMOVED_FROM_QUEUE],
              () => {
                const tabsLoading = getTabsFetchable(getState(), tabParamsKey).loading
                if (!tabsLoading) {
                  dispatch(fetchTabs(tabParamsKey, {}, false))
                }
              },
            ),
          )
        }

        if (buildId != null) {
          subscriptions.add(
            context,
            `fetchBuild:${buildId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(buildId!), {
                    withBuildTypeDetails: true,
                  }),
                ),
              ).unsubscribe,
          )
        }

        dispatch(preloadBuildTypeData(context, buildTypeId, internalId))
      },
  },
  {
    path: Routes.BUILD_UNKNOWN_BUILDTYPE,
    Component: BuildPage,
  },
  {
    path: Routes.COMPARE_BUILDS,
    Component: CompareBuildsPage,
    preload:
      ({params, prevParams, queryParams, prevQueryParams, context}: PreloadParams): AppThunk =>
      dispatch => {
        if (params.buildIdSource !== prevParams?.buildIdSource) {
          const sourceId = toBuildId(params.buildIdSource!)
          subscriptions.add(
            context,
            `fetchSourceBuild:${sourceId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(sourceId)),
                ),
              ).unsubscribe,
          )
        }
        if (
          queryParams.withBuildId != null &&
          queryParams.withBuildId !== prevQueryParams?.withBuildId
        ) {
          const compareWithId = toBuildId(queryParams.withBuildId)
          subscriptions.add(
            context,
            `fetchCompareWithBuild:${compareWithId}`,
            () =>
              dispatch(
                restApi.endpoints.getBuildNormalizedAsList.initiate(
                  getSingleBuildArg(getBuildLocator(compareWithId)),
                ),
              ).unsubscribe,
          )
        }
        if (
          queryParams.withBuildId != null &&
          (params.buildIdSource !== prevParams?.buildIdSource ||
            queryParams.withBuildId !== prevQueryParams?.withBuildId)
        ) {
          const sourceId = toBuildId(params.buildIdSource!)
          const compareWithId = toBuildId(queryParams.withBuildId)
          dispatch(fetchCompareBuildsList(sourceId, compareWithId))
        }
      },
  },
  USE_NEW_TEST_HISTORY_PAGE
    ? {
        path: Routes.TEST,
        Component: TestHistoryPage,
      }
    : null,
  USE_NEW_CHANGE_PAGE
    ? {
        path: Routes.CHANGE,
        Component: ChangePage,
        preload:
          ({params, queryParams, context}: PreloadParams): AppThunk =>
          dispatch => {
            const changeId = toChangeId(params.changeId ?? '')
            const personal = queryParams?.personal === 'true'
            const locator = `id:${stringifyId(changeId)},personal:${personal}`

            subscriptions.add(
              context,
              `fetchChanges:${locator}`,
              () =>
                dispatch(restApi.endpoints.getAllChanges.initiate(getChangesArg(locator)))
                  .unsubscribe,
            )
            dispatch(fetchChangeStatus(locator))
            dispatch(fetchTabs(getTabParamsKey({changeId, personal})))
          },
      }
    : null,
  USE_NEW_CHANGES_PAGE
    ? {
        path: Routes.CHANGES,
        Component: ChangesPage,
      }
    : null,
  {
    path: Routes.GUIDES,
    Component: GuidesPage,
  },
  USE_NEW_QUEUE_PAGE
    ? {
        path: Routes.QUEUE,
        Component: QueuePage,
        preload:
          ({queryParams, context}: PreloadParams): AppThunk =>
          (dispatch, getState) => {
            const state = getState()

            subscriptions.add(context, 'queuePools', () => dispatch(subscribeOnPoolCounters()))

            const page = (queryParams.page && parseInt(queryParams.page, 10)) || 1
            dispatch(
              submitPager({
                total: 0,
                show: false,
                currentPage: page,
                precountedPages: 3,
                pageSize: 50,
                lookupLimit: 10000,
                lookupDelta: 10000,
              }),
            )
            const activeAgentPoolIdString = queryParams.agentPoolId
            const activeAgentPoolId =
              activeAgentPoolIdString != null ? toAgentPoolId(activeAgentPoolIdString) : null
            const onlyMyPersonal = queryParams.onlyMyPersonal === 'true'
            const locator = getQueuePageLocator(activeAgentPoolId, onlyMyPersonal)

            if (!getBuildsInited(state, locator)) {
              dispatch(
                fetchBuildsData({
                  locator,
                  withPager: true,
                  requestOptions: {
                    customEndpoint: '/buildQueue',
                    withBuildTypeDetails: true,
                    withSnapshotDependencies: false,
                    withQueuedInfo: true,
                    essential: true,
                  },
                }),
              )
            }
          },
      }
    : null,
  pipelinesEnabled ? {path: Routes.PIPELINES, Component: PipelinesPages} : null,
  // !!!!DON'T FORGET TO ADD THE MAPPING TO web-startup/WEB-INF/web.xml AND /react-ui/src/routes/shared-routes.json!!!
].filter(notNull)
const baseRoutes = routes.map(route => ({...route, path: getBaseRoute(route.path)}))
export const preloadRoute =
  (to: To | null | undefined, prevLocation?: Location): AppThunk =>
  async dispatch => {
    if (to == null) {
      return
    }

    const location = typeof to === 'string' ? parsePath(to) : to
    const path = location.pathname ?? prevLocation?.pathname ?? ''
    const match = matchRoutes(baseRoutes, path)?.[0]
    const route = match?.route ?? favoriteProjectsRoute
    const params = match?.params ?? {}
    const {preload} = route as PreloadableRoute

    if (subscriptions.currentContext != null) {
      subscriptions.clear(subscriptions.currentContext, true)
    }
    const context = Symbol()
    subscriptions.currentContext = context

    if (preload != null) {
      const prevMatch = prevLocation != null ? matchRoutes(baseRoutes, prevLocation)?.[0] : null
      const prevRoute = prevMatch?.route ?? favoriteProjectsRoute
      const isSameRoute = prevRoute === route
      await dispatch(
        preload({
          params,
          queryParams: queryToObject(location.search),
          prevParams: isSameRoute ? prevMatch?.params ?? {} : null,
          prevQueryParams:
            isSameRoute && prevLocation != null ? queryToObject(prevLocation.search) : null,
          path,
          context,
        }),
      )
    }
    subscriptions.clear(context)
  }
