// Those imports magically fix styles order
import '@jetbrains/ring-ui/components/button/button.css'
import '@jetbrains/ring-ui/components/link/link.css'
import './components/EditProjectSidebar/EditProjectSidebar.css'

import AlertService, {AlertItem} from '@jetbrains/ring-ui/components/alert-service/alert-service'
import type {AlertType} from '@jetbrains/ring-ui/components/alert/alert'
import {Anchor} from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {
  ControlsHeight,
  ControlsHeightContext,
} from '@jetbrains/ring-ui/components/global/controls-height'
import {Type as ListItemType} from '@jetbrains/ring-ui/components/list/consts'
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import {MarkdownProps} from '@jetbrains/ring-ui/components/markdown/markdown'
import type {MessageProps} from '@jetbrains/ring-ui/components/message/message'
import {Directions as PopupDirections} from '@jetbrains/ring-ui/components/popup/popup.consts'
import type {SelectAttrs} from '@jetbrains/ring-ui/components/select/select'
import type {PlaceId} from '@jetbrains/teamcity-api'
import type {AlertKey} from '@jetbrains/teamcity-api/types/services/AlertService.d'
import * as React from 'react'
import {createRoot} from 'react-dom/client'
import {renderToStaticMarkup} from 'react-dom/server'
import {Provider} from 'react-redux'

import {
  addPlugin,
  dummyAction,
  fetchCurrentUserData,
  openDialog,
  receiveAgentsInCloud,
  receiveCanAuthorizePoolPermissions,
  receiveCanChangeStatusPoolPermissions,
  receiveServerInfo,
  removePlugin,
  setIsExperimentalUI,
  setRouteAvailabilityResponse,
  setUserProperty,
  storeUrlExtensions,
} from './actions'
import {multiplyReceiveBuild, receiveBuild, receiveBuildWSData} from './actions/builds'
import {fetchDslOptions} from './actions/fetch'
import {fetchOverview} from './actions/overview'
import {
  fetchArchivedProjects,
  fetchProjectsDataWithOptions,
  fetchProjectsWithArchivedData,
  fetchProjectsWithBuildTypesData,
} from './actions/projects'
import {AdminProjectsSidebarProps} from './components/App/AdminProjectsSidebar/AdminProjectsSidebar'
import {preloadRoute} from './components/App/App.routes'
import Header from './components/App/Header/Header.lazy'

import {isSakuraHeaderUsed, setSakuraHeaderUsed} from './components/App/Header/Header.utils'
import HeaderHealthLazy from './components/App/Header/HealthItem/HealthItem.lazy'
import type {SelectBuildRunnerProps} from './components/App/SelectBuildRunners/SelectBuildRunners.types'
import type {OwnProps as CleanupProps} from './components/CleanupBuildType/Cleanup.types'
import type {OwnProps as CleanupPoliciesProps} from './components/CleanupProject/CleanupPolicies.types'
import type {AgentAuthProps} from './components/common/AgentAuth/AgentAuth.types'
import {receiveAgents} from './components/common/Agents/Agents.actions'
import AgentsFetcher from './components/common/Agents/Agents.fetcher'
import type {AgentsOwnProps} from './components/common/Agents/Agents.types'
import {Avatar} from './components/common/Avatar/Avatar'
import AvatarEditor from './components/common/AvatarEditor/AvatarEditor'
import BranchLabel from './components/common/BranchLabel/BranchLabel'
import type {BranchSelectProps} from './components/common/BranchSelect/BranchSelect.types'
import BuildApproval from './components/common/BuildApproval/BuildApproval'
import BuildArtifacts from './components/common/BuildArtifacts/BuildArtifacts.container'
import BuildArtifactsTree from './components/common/BuildArtifactsTree/BuildArtifactsTree'
import BuildDuration from './components/common/BuildDuration/BuildDuration.container'
import BuildNumberContainer, {
  BuildNumberAPI,
} from './components/common/BuildNumber/BuildNumber.container'
import BuildActions from './components/common/Builds/Build/BuildActions/BuildActions.container'
import type {BuildsOwnProps} from './components/common/Builds/Builds.types'
import type {BuildStatusWidgetBaseProps} from './components/common/BuildStatusWidget/BuildStatusWidget.types'
import BuildTag from './components/common/BuildTag/BuildTag'
import type {Props as BuildTypeOverviewProps} from './components/common/BuildTypeOverview/BuildTypeOverview'
import {ContentPanelAPI} from './components/common/ContentPanel/ContentPanel'
import Icon from './components/common/Icon/Icon'
import MiddleEllipsis from './components/common/MiddleEllipsis/MiddleEllipsis'
import PluginPlace from './components/common/Plugin/Plugin'
import ProblemsSummary from './components/common/ProblemsSummary/ProblemsSummary'
import type {ProjectBuildTypeSelectProps} from './components/common/ProjectBuildTypeSelect/ProjectBuildTypeSelect'
import type {ProjectsPopupProps} from './components/common/ProjectsPopup/ProjectsPopup'
import ProjectsPopup from './components/common/ProjectsPopup/ProjectsPopup.lazy'
import {RouterButtonAPI, RouterLinkAPI} from './components/common/RouterLink/RouterLink'
import ServiceWorkerChecker from './components/common/ServiceWorkerChecker/ServiceWorkerChecker'
import {fetchProjectsForAllFederationServers} from './components/common/SidebarFooter/SidebarFooter.actions'
import StopBuild from './components/common/StopBuild/StopBuild.container'
import Visible from './components/common/Visible/Visible'
import {observeVisibilityMulticast, stopObserving} from './components/common/Visible/Visible.utils'
import {hideDsl, storeDslFragment} from './components/Dsl/Dsl.actions'
import type {BuildTypeDslEditorProps} from './components/Dsl/DslEditor/BuildTypeDslEditor.container'
import type {FragmentDslEditorProps} from './components/Dsl/DslEditor/FragmentDslEditor.container'
import HelpDropdown from './components/HelpDropdown/HelpDropdown'
import {addHint, removeHint} from './components/Hints/Hints.actions'
import type {HintId, HintType} from './components/Hints/Hints.types'
import {BuildProblemInvestigationHistoryPopupDummy} from './components/InvestigationHistory/InvestigationHistoryPopup/BuildProblemInvestigationHistoryPopup.container'
import {BuildTypeInvestigationHistoryPopupDummy} from './components/InvestigationHistory/InvestigationHistoryPopup/BuildTypeInvestigationHistoryPopup.container'
import {TestInvestigationHistoryPopupDummy} from './components/InvestigationHistory/InvestigationHistoryPopup/TestInvestigationHistoryPopup.container'
import MetricsTab from './components/MetricsTab/MetricsTab'
import type {OwnProps as BuildTypeHistoryProps} from './components/pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.types'
import {updateQueue} from './components/pages/QueuePage/QueueBuildList/QueueBuildList.streams'
import ProjectOrBuildTypeIcon from './components/ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon'
import SakuraReleaseBanner from './components/SakuraRelease/SakuraRelease.banner'
import ShowDsl from './components/ShowDsl/ShowDsl.container'
import SkipNav from './components/SkipNav/SkipNav'
import type {OpenInExperimentalUIProps} from './components/ToggleExperimentalUI/ToggleExperimentalUI.types'
import {
  getExperimentalUIHref,
  redirectToExperimentalUI,
} from './components/ToggleExperimentalUI/ToggleExperimentalUI.utils'
import type {AgentHistoryProps} from './containers/AgentHistory'
import type {AllBuildsOwnProps} from './containers/AllBuilds'
import BuildLineLayout from './containers/BuildLineLayout'
import BuildsFetcher from './containers/BuildsFetcher'
import {EntityPathAPI} from './containers/EntityPath'
import TagsFilters from './containers/TagsFilters'
import {ReactModule, wrappedLazy} from './containers/wrappedLazy'
import {EntityProvider} from './contexts/entity'
import type {State} from './reducers/types'
import {getEndpointForBranches, requestIsBranchPresent} from './rest/branches'
import {restRoot} from './rest/consts'
import {browserHistory} from './routes'
import HistoryProvider from './routes/HistoryProvider'
import {getAgents, getAgentsReady} from './selectors'
import {restApi} from './services/rest'
import store from './store'
import type {
  AgentId,
  AgentType,
  BranchType,
  BuildId,
  BuildType,
  BuildTypeId,
  BuildTypeInternalId,
  NormalizedBuildType,
  PoolPermissions,
  ProblemId,
  ProblemOccurrenceId,
  ProjectId,
  ProjectInternalId,
  ProjectOrBuildTypeNode,
  RouteAvailabilityResponse,
  ServerInfo,
  TestId,
  UrlExtension,
} from './types'
import {DialogType, getBuildTypeFilter, ROOT_PROJECT_ID, stringifyId} from './types'
import {base_uri} from './types/BS_types'
import {defaultBranch} from './utils/branchNames'
import type {ColorValueArray} from './utils/colorByString'
import {getColorRgbByString} from './utils/colorByString'
import {getSandbox, removeSandbox} from './utils/fastdom'
import {getDisplayName} from './utils/getDisplayName'
import {WritableKeyValue} from './utils/object'
import {queryToObject} from './utils/queryParams'
import setupMoment from './utils/setupMoment'
import showHide from './utils/showHide'
import createSimpleStream from './utils/simpleStream'
import {
  DEFAULT_DEBOUNCE_INTERVAL,
  getTopics,
  POSTPONE_METHOD,
  subscribeOnOverallEvents,
} from './utils/subscriber'
import {
  PROJECT_ARCHIVED,
  PROJECT_CREATED,
  PROJECT_DEARCHIVED,
  PROJECT_REMOVED,
} from './utils/subscriptionEvents'
import type {UserProperty} from './utils/userProperties'
import {UserProperties} from './utils/userProperties'
import {
  registerServiceWorker,
  setupServiceWorker,
  unregisterServiceWorker,
} from './workers/setupServiceWorker'
import {CACHE_PROJECTS_TREE} from './workers/sw.constants'
import {requestSWUpdate} from './workers/sw.lifecycle'

const AgentHistory: React.ComponentType<AgentHistoryProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "AgentHistory", webpackPrefetch: true */
      './containers/AgentHistory'
    ),
)
const AllBuilds: React.ComponentType<AllBuildsOwnProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "AllBuilds", webpackPrefetch: true */
      './containers/AllBuilds'
    ),
)
const TwoFactorAuthSettings = wrappedLazy(
  () => import('./components/TwoFactorAuth/TwoFactorAuthSettings/TwoFactorAuthSettings'),
)
const TwoFactorAuthLoginScreen = wrappedLazy(
  () => import('./components/TwoFactorAuth/TwoFactorAuthLoginScreen/TwoFactorAuthLoginScreen'),
)
const HttpsConfiguration = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "HttpsConfiguration", webpackPrefetch: true */
      './components/pages/HttpsConfigurationPage/HttpsConfigurationPage'
    ),
  <LoaderInline>{'Loading...'}</LoaderInline>,
)
const BuildsContainer: React.ComponentType<BuildsOwnProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Builds", webpackPrefetch: true */
      './components/common/Builds/Builds.container'
    ),
)
const BuildsTvMode = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "BuildsTvMode", webpackPrefetch: true */
      './components/common/BuildsTvMode/BuildsTvMode.container'
    ),
)
const BuildTypeHistory: React.ComponentType<BuildTypeHistoryProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "BuildTypeHistory", webpackPrefetch: true */
      './components/pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeHistory/BuildTypeHistory.container'
    ),
)
const BuildTypeOverview: React.ComponentType<BuildTypeOverviewProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "BuildTypeOverview", webpackPrefetch: true */
      './components/common/BuildTypeOverview/BuildTypeOverview'
    ),
)
const Cleanup: React.ComponentType<CleanupProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Cleanup", webpackPrefetch: true */
      './components/CleanupBuildType/Cleanup.container'
    ),
)
const CleanupPolicies: React.ComponentType<CleanupPoliciesProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "CleanupPolicies", webpackPrefetch: true */
      './components/CleanupProject/CleanupPolicies.container'
    ),
)
const EditFederation: React.ComponentType = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "EditFederation", webpackPrefetch: true */
      './components/EditFederation/EditFederation'
    ),
  <LoaderInline>{'Loading servers...'}</LoaderInline>,
)
const Hints: React.ComponentType = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Hints", webpackPrefetch: true */
      './components/Hints/Hints'
    ),
)
const HintPopup = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "HintPopup", webpackPrefetch: true */
      './components/Hints/HintPopup/HintPopup'
    ),
)
const FavoriteBuilds = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "FavoriteBuilds", webpackPrefetch: true */
      './components/pages/FavoriteBuildsPage/FavoriteBuilds/FavoriteBuilds'
    ),
)
const ProjectBuildTypeSelect: React.ComponentType<ProjectBuildTypeSelectProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "ProjectBuildTypeSelect", webpackPrefetch: true */
      './components/common/ProjectBuildTypeSelect/ProjectBuildTypeSelect'
    ),
)
const AgentAuth: React.ComponentType<AgentAuthProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "AgentAuth", webpackPrefetch: true */
      './components/common/AgentAuth/AgentAuth'
    ),
)
const BranchSelect = wrappedLazy<BranchSelectProps>(
  () =>
    import(
      /* webpackChunkName: "BranchSelect", webpackPrefetch: true */
      './components/common/BranchSelect/BranchSelect.container'
    ),
)
const BuildTypeDslEditor = wrappedLazy<BuildTypeDslEditorProps>(
  () =>
    import(
      /* webpackChunkName: "BuildTypeDslEditor", webpackPrefetch: true */
      './components/Dsl/DslEditor/BuildTypeDslEditor.container'
    ),
)
const FragmentDslEditor: React.ComponentType<FragmentDslEditorProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "FragmentDslEditor", webpackPrefetch: true */
      './components/Dsl/DslEditor/FragmentDslEditor.container'
    ),
)
const Pager: React.ComponentType<any> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Pager", webpackPrefetch: true */
      '@jetbrains/ring-ui/components/pager/pager'
    ),
)

const Markdown: React.ComponentType<MarkdownProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Markdown", webpackPrefetch: true */
      '@jetbrains/ring-ui/components/markdown/markdown'
    ),
)

const OpenInExperimentalUI: React.ComponentType<OpenInExperimentalUIProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "OpenInExperimentalUI", webpackPrefetch: true */
      './components/ToggleExperimentalUI/OpenInExperimentalUI.container'
    ),
)
const ErrorAlerts: React.ComponentType = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "ErrorAlerts", webpackPrefetch: true */
      './components/common/ErrorAlerts/ErrorAlerts.container'
    ),
)
const AgentsContainer: React.ComponentType<AgentsOwnProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "AgentsContainer", webpackPrefetch: true */
      './components/common/Agents/Agents.container'
    ),
)
const App: React.ComponentType = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "App", webpackPrefetch: true */
      './components/App/App.container'
    ),
)
const Message: React.ComponentType<MessageProps> = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Message", webpackPrefetch: true */
      '@jetbrains/ring-ui/components/message/message'
    ),
)
const ServiceMessage = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "ServiceMessage", webpackPrefetch: true */
      './components/common/ServiceMessage/ServiceMessage'
    ),
)

const Select = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Select", webpackPrefetch: true */
      '@jetbrains/ring-ui/components/select/select'
    ) as Promise<ReactModule<SelectAttrs>>,
)

const BuildStatusWidget = wrappedLazy<BuildStatusWidgetBaseProps>(
  () =>
    import(
      /* webpackChunkName: "BuildStatusWidget", webpackPrefetch: true */
      './components/common/BuildStatusWidget/BuildStatusWidget.container'
    ),
)

const Sorter = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "Sorter", webpackPrefetch: true */
      './components/common/Sorter/Sorter.container'
    ),
)

const AdminProjectsSidebar = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "AdminProjectsSidebar", webpackPrefetch: true */
      './components/App/AdminProjectsSidebar/AdminProjectsSidebar'
    ),
)

const AdminSelectBuildRunner = wrappedLazy(
  () =>
    import(
      /* webpackChunkName: "SelectBuildRunners", webpackPrefetch: true */
      './components/App/SelectBuildRunners/SelectBuildRunners'
    ),
)

setupMoment()
const dataCache: WritableKeyValue<string, string> = {}
const dataCacheTimeouts: WritableKeyValue<string, number> = {}
const STALE_TIMEOUT = 60000

const roots = new WeakMap()
function createRootAndRender(element: React.ReactElement, container: Element) {
  let root = roots.get(container)
  if (root == null) {
    root = createRoot(container)
    roots.set(container, root)
  }
  root.render(element)
}

// noinspection JSUnusedGlobalSymbols
const ReactUI = {
  buildNumber: process.env.BUILD_NUMBER ?? ('Local' as string),
  buildId: process.env.TEAMCITY_BUILD_ID,
  isExperimentalUI: false,
  store,
  createElement: React.createElement,
  getExperimentalUIHref,

  unmountComponentAtNode(el: HTMLElement) {
    try {
      roots.get(el)?.unmount()
      roots.delete(el)
    } catch {
      // ignore errors
    }
  },

  createSimpleStream,
  updateQueue,
  UserProperties,
  containers: new Map() as Map<string, HTMLElement>,

  getContainer(id: string): HTMLElement {
    const placeholder = document.getElementById(id)
    const tagName = placeholder?.tagName ?? 'div'
    let container = this.containers.get(id)

    if (container == null || !container.hasChildNodes()) {
      this.performCleanups(container)
      this.clearUnusedTO(id)
      container = document.createElement(tagName)
      this.containers.set(id, container)
    }

    return container
  },

  unusedContainers: new Map() as Map<string, number>,

  markAsUnused(id: string) {
    this.unusedContainers.set(
      id,
      window.setTimeout(() => this.unmountContainer(id), STALE_TIMEOUT),
    )
  },

  clearUnusedTO(id: string) {
    if (!this.unusedContainers.has(id)) {
      return
    }

    window.clearTimeout(this.unusedContainers.get(id))
    this.unusedContainers.delete(id)
  },

  unmountContainer(id: string) {
    const container = this.getContainer(id)
    this.performCleanups(container)
    this.containers.delete(id)
    this.clearUnusedTO(id)
  },

  alreadyRendered: new WeakSet() as WeakSet<HTMLElement>,
  cleanups: new WeakMap() as WeakMap<HTMLElement, ReadonlyArray<() => unknown>>,

  addCleanup(element: HTMLElement, cleanup: () => unknown) {
    const currentCleanups = this.cleanups.get(element) ?? []
    this.cleanups.set(element, currentCleanups.concat(cleanup))
  },

  performCleanups(element: HTMLElement | null | undefined) {
    if (element == null) {
      return
    }

    if (
      element.hasAttribute('data-react-root') ||
      element.hasAttribute('data-react-refreshable-root')
    ) {
      this.unmountComponentAtNode(element)
    }

    const currentCleanups = this.cleanups.get(element) ?? []
    currentCleanups.forEach(cleanup => cleanup())
    removeSandbox(element)
    this.cleanups.delete(element)
    this.alreadyRendered.delete(element)
    this.elementsToRenderWhenVisible.delete(element)
  },

  unmountWhenDetached(element: HTMLElement) {
    setTimeout(() => {
      this.getSandbox(element).mutate(() => {
        if (document.body?.contains(element)) {
          this.unmountWhenDetached(element)
        } else {
          this.performCleanups(element)
        }
      })
    }, STALE_TIMEOUT)
  },

  render(
    reactElement: React.ReactElement,
    elementOrIdOrNull: (HTMLElement | null | undefined) | string,
    type: React.ComponentType<any> | string = reactElement.type,
  ) {
    if (elementOrIdOrNull == null) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(`Tried to render ${getDisplayName(type)} into a non-existent DOM element`)
      }

      return
    }

    // Flow needs a temp var to ensure immutability
    const elementOrId = elementOrIdOrNull

    if (typeof elementOrId === 'string') {
      this.clearUnusedTO(elementOrId)
    }

    const container: HTMLElement =
      typeof elementOrId === 'string' ? this.getContainer(elementOrId) : elementOrId
    getSandbox(container).mutate(() => {
      if (typeof elementOrId === 'string') {
        container.id = elementOrId
        container.setAttribute('data-react-refreshable-root', 'true')
      } else if (elementOrId != null) {
        elementOrId.setAttribute('data-react-root', 'true')
      }

      const alreadyRendered = this.alreadyRendered.has(container)
      const observableParent = alreadyRendered
        ? null
        : container.closest('[data-observe-visibility]')

      if (observableParent instanceof HTMLElement) {
        this.renderWhenVisible(reactElement, container, observableParent)
      } else {
        createRootAndRender(reactElement, container)
        this.alreadyRendered.add(container)
        this.unmountWhenDetached(container)
      }

      if (typeof elementOrId === 'string') {
        const placeholder = document.getElementById(elementOrId)

        if (placeholder != null && placeholder.parentNode) {
          placeholder.parentNode.replaceChild(container, placeholder)
        } else {
          this.unmountContainer(elementOrId)
        }
      }
    })
  },

  elementsToRenderWhenVisible: new WeakMap() as WeakMap<HTMLElement, React.ReactElement>,

  renderWhenVisible(
    reactElement: React.ReactElement,
    container: HTMLElement,
    observableParent: HTMLElement,
  ) {
    const alreadyWaiting = this.elementsToRenderWhenVisible.has(container)
    this.elementsToRenderWhenVisible.set(container, reactElement)

    if (!alreadyWaiting) {
      const visibilityHandler = (isVisible: boolean) => {
        if (isVisible) {
          getSandbox(container).mutate(() => {
            const currentElement = this.elementsToRenderWhenVisible.get(container)

            if (currentElement != null) {
              createRootAndRender(currentElement, container)
            }
          })
          this.alreadyRendered.add(container)
          this.unmountWhenDetached(container)
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          stopListening()
        }
      }

      const mouseHandler = () => visibilityHandler(true)

      container.addEventListener('mouseenter', mouseHandler)
      const unsubscribe = observeVisibilityMulticast(observableParent, visibilityHandler)

      const stopListening = () => {
        unsubscribe()
        container.removeEventListener('mouseenter', mouseHandler)
      }

      this.addCleanup(container, stopListening)
    }
  },

  renderToStaticMarkup,

  createAndRender<P>(
    elementOrId: HTMLElement | string,
    Type: React.ComponentType<P>,
    props: P & JSX.IntrinsicAttributes,
  ) {
    this.render(<Type {...props} />, elementOrId)
  },

  createAndRenderStatic<P>(
    Type: React.ComponentType<P>,
    props: P & JSX.IntrinsicAttributes,
  ): string {
    return this.renderToStaticMarkup(<Type {...props} />)
  },

  createConnected<P>(Type: React.ComponentType<P>, props: P & JSX.IntrinsicAttributes) {
    return (
      <Provider store={this.store}>
        <HistoryProvider>
          <ControlsHeightContext.Provider value={ControlsHeight.S}>
            <Type {...props} />
          </ControlsHeightContext.Provider>
        </HistoryProvider>
      </Provider>
    )
  },

  renderConnected<P>(
    elementOrId: HTMLElement | string,
    Type: React.ComponentType<P>,
    props: P & JSX.IntrinsicAttributes,
  ) {
    this.render(this.createConnected(Type, props), elementOrId, Type)
  },

  getAgents,
  getAgentsReady,
  Anchor,
  AlertService,
  OpenInExperimentalUI,
  PopupDirections,
  Message,
  ServiceMessage,
  HelpDropdown,
  HintPopup,
  AllBuilds,
  BranchSelect: function BranchSelectContainer({
    selected: initialSelected,
    onSelect,
    ...restProps
  }: BranchSelectProps): React.ReactNode {
    const [selected, setSelected] = React.useState(initialSelected)
    const handleSelect = React.useCallback(
      (nextSelected: BranchType | null | undefined, replace?: boolean) => {
        setSelected(nextSelected)
        onSelect(nextSelected, replace)
      },
      [onSelect],
    )
    return <BranchSelect {...restProps} selected={selected} onSelect={handleSelect} />
  },
  BuildBranch: BranchLabel,
  BuildNumberAPI,
  BuildTag,
  ContentPanelAPI,
  EditFederation,
  EntityPathAPI,
  ProblemsSummary,
  ProjectOrBuildTypeIcon,
  MiddleEllipsis,
  BuildArtifactsTree,
  Select,
  ListItemType,
  ErrorAlerts,
  SkipNav,
  ProjectBuildTypeSelect,
  RouterLinkAPI,
  RouterButtonAPI,
  restApi,

  onChange<T>(selector: (arg0: State) => T, changeHandler: (arg0: T) => unknown): () => unknown {
    let prevValue: T | null | undefined

    const handler = () => {
      const value = selector(this.store.getState())

      if (value !== prevValue) {
        changeHandler(value)
      }

      prevValue = value
    }

    handler()
    return this.store.subscribe(handler)
  },

  setUserProperty(name: UserProperty, value: string) {
    this.store.dispatch(setUserProperty(name, value))
  },

  receiveBuild(data: BuildType) {
    this.store.dispatch(receiveBuild(data))
  },

  receiveBuildAsJSON(buildId: BuildId, encodedData: string) {
    const cacheKey = `build-data-${stringifyId(buildId)}`

    if (dataCache[cacheKey] === encodedData) {
      return
    }

    dataCache[cacheKey] = encodedData
    clearTimeout(dataCacheTimeouts[cacheKey])
    dataCacheTimeouts[cacheKey] = window.setTimeout(() => {
      delete dataCache[cacheKey]
      delete dataCacheTimeouts[cacheKey]
    }, STALE_TIMEOUT)
    const data: BuildType = JSON.parse(encodedData)
    this.store.dispatch(multiplyReceiveBuild(data))
  },

  receiveAgents(data: ReadonlyArray<AgentType>) {
    this.store.dispatch(receiveAgents(data))
  },

  receiveCanChangeStatusPoolPermissions(data: PoolPermissions) {
    this.store.dispatch(receiveCanChangeStatusPoolPermissions(data))
  },

  receiveCanAuthorizePoolPermissions(data: PoolPermissions) {
    this.store.dispatch(receiveCanAuthorizePoolPermissions(data))
  },

  receiveAgentsInCloud(data: ReadonlyArray<AgentId>) {
    if (data) {
      this.store.dispatch(receiveAgentsInCloud(data))
    }
  },

  receiveBuildWSData(data: NormalizedBuildType) {
    this.store.dispatch(receiveBuildWSData(data))
  },

  setIsExperimentalUI() {
    this.isExperimentalUI = true
    this.store.dispatch(setIsExperimentalUI())
  },

  isSakuraHeaderUsed(): boolean {
    return isSakuraHeaderUsed()
  },

  setSakuraHeaderUsed(): boolean {
    return setSakuraHeaderUsed()
  },

  storeUrlExtensions(urls: ReadonlyArray<UrlExtension<any>>) {
    this.store.dispatch(storeUrlExtensions(urls))
  },

  setRouteAvailabilityResponse(response: RouteAvailabilityResponse) {
    this.store.dispatch(setRouteAvailabilityResponse(response, location.pathname))
  },

  dummyAction() {
    this.store.dispatch(dummyAction())
  },

  renderBuildActions(
    elementOrId: HTMLElement | string,
    buildId: BuildId,
    props: JSX.LibraryManagedAttributes<
      typeof BuildActions,
      React.ComponentProps<typeof BuildActions>
    >,
  ) {
    this.renderConnected(elementOrId, EntityProvider, {
      buildId,
      children: <BuildActions {...props} />,
    })
  },

  renderSakuraReleaseBanner(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof SakuraReleaseBanner>,
  ) {
    this.renderConnected(elementOrId, SakuraReleaseBanner, props)
  },

  sakuraReleaseBannerHref: '',
  setSakuraReleaseBannerHref(href: string) {
    this.sakuraReleaseBannerHref = href
  },

  renderBuildArtifacts(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof BuildArtifacts,
      React.ComponentProps<typeof BuildArtifacts>
    >,
  ) {
    this.renderConnected(elementOrId, BuildArtifacts, props)
  },

  renderBuildNumber(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof BuildNumberContainer,
      React.ComponentProps<typeof BuildNumberContainer>
    >,
  ) {
    this.renderConnected(elementOrId, BuildNumberContainer, props)
  },

  renderStop(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<typeof StopBuild, React.ComponentProps<typeof StopBuild>>,
  ) {
    this.renderConnected(elementOrId, StopBuild, props)
  },

  renderDuration(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof BuildDuration,
      React.ComponentProps<typeof BuildDuration>
    >,
  ) {
    this.store.dispatch(fetchCurrentUserData())
    this.renderConnected(elementOrId, BuildDuration, props)
  },

  fetchCurrentUserdData() {
    this.store.dispatch(fetchCurrentUserData())
  },

  renderBuilds(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof BuildsContainer,
      React.ComponentProps<typeof BuildsContainer>
    >,
    fetcherProps: JSX.LibraryManagedAttributes<
      typeof BuildsFetcher,
      React.ComponentProps<typeof BuildsFetcher>
    >,
    layoutProps: JSX.LibraryManagedAttributes<
      typeof BuildLineLayout,
      React.ComponentProps<typeof BuildLineLayout>
    >,
  ) {
    this.renderConnected(elementOrId, BuildsFetcher, {
      ...fetcherProps,
      children: (
        <BuildLineLayout {...layoutProps}>
          <BuildsContainer {...props} />
        </BuildLineLayout>
      ),
    })
  },

  renderAgentHistory(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof AgentHistory,
      React.ComponentProps<typeof AgentHistory>
    >,
  ) {
    this.store.dispatch(fetchProjectsWithArchivedData())
    this.renderConnected(elementOrId, AgentHistory, props)
  },

  renderBuildTypeHistory(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof BuildTypeHistory,
      React.ComponentProps<typeof BuildTypeHistory>
    >,
  ) {
    this.renderConnected(elementOrId, BuildTypeHistory, props)
  },

  renderShortBuildTypeHistory(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof BuildTypeOverview,
      React.ComponentProps<typeof BuildTypeOverview>
    >,
  ) {
    this.renderConnected(elementOrId, BuildTypeOverview, props)
  },

  renderFavoriteBuilds(elementOrId: HTMLElement | string) {
    this.store.dispatch(fetchProjectsWithArchivedData())
    this.renderConnected(elementOrId, FavoriteBuilds, {})
  },

  renderOverview(
    elementOrId: HTMLElement | string,
    urls: ReadonlyArray<UrlExtension<any>>,
    props: JSX.LibraryManagedAttributes<typeof App, React.ComponentProps<typeof App>>,
  ) {
    this.storeUrlExtensions(urls)
    const bypassSubscription = window.internalProps['teamcity.ui.sidebar.skipWSEventsSubscription']
    const handler = () => {
      this.store.dispatch(fetchOverview())
      this.store.dispatch(
        fetchProjectsWithBuildTypesData(true, ROOT_PROJECT_ID, {
          cache: CACHE_PROJECTS_TREE,
          withDescription: false,
        }),
      )

      const state = this.store.getState()
      const {showArchivedProjects} = state.sidebar
      if (showArchivedProjects) {
        this.store.dispatch(fetchArchivedProjects())
      }
    }

    if (bypassSubscription) {
      handler()
    } else {
      subscribeOnOverallEvents(
        getTopics('', [
          PROJECT_REMOVED,
          PROJECT_CREATED,
          PROJECT_ARCHIVED,
          PROJECT_DEARCHIVED, // TODO reenable UPDATED events after making sure that only one request is scheduled in case of multiple open tabs
          // PROJECT_UPDATED,
          // BUILD_TYPE_REGISTERED,
          // BUILD_TYPE_UNREGISTERED,
          // BUILD_TYPE_UPDATED,
        ]),
        handler,
        DEFAULT_DEBOUNCE_INTERVAL,
        POSTPONE_METHOD.DEBOUNCE,
      )
    }

    this.store.dispatch(fetchProjectsForAllFederationServers())
    this.store.dispatch(preloadRoute(browserHistory.location))
    let prevLocation = browserHistory.location
    browserHistory.listen(({action, location}) => {
      if (action === 'POP') {
        this.store.dispatch(preloadRoute(location, prevLocation))
      }
      prevLocation = location
    })
    const element =
      typeof elementOrId === 'string' ? document.getElementById(elementOrId) : elementOrId
    if (element == null) {
      throw new Error(`Can't find element with id '${elementOrId}'`)
    } else {
      createRootAndRender(this.createConnected(App, props), element)
    }
  },

  renderSelectBuildRunner(elementOrId: HTMLElement | string, props: SelectBuildRunnerProps) {
    this.renderConnected(elementOrId, AdminSelectBuildRunner, props)
  },

  renderAdminSidebar(elementOrId: HTMLElement | string, props: AdminProjectsSidebarProps) {
    const bypassSubscription = window.internalProps['teamcity.ui.sidebar.skipWSEventsSubscription']
    const handler = () => {
      this.store.dispatch(fetchOverview())
      this.store.dispatch(
        fetchProjectsWithBuildTypesData(true, ROOT_PROJECT_ID, {
          cache: CACHE_PROJECTS_TREE,
          withDescription: false,
        }),
      )
    }

    if (bypassSubscription) {
      handler()
    } else {
      subscribeOnOverallEvents(
        getTopics('', [
          PROJECT_REMOVED,
          PROJECT_CREATED,
          PROJECT_ARCHIVED,
          PROJECT_DEARCHIVED, // TODO reenable UPDATED events after making sure that only one request is scheduled in case of multiple open tabs
          // PROJECT_UPDATED,
          // BUILD_TYPE_REGISTERED,
          // BUILD_TYPE_UNREGISTERED,
          // BUILD_TYPE_UPDATED,
        ]),
        handler,
        DEFAULT_DEBOUNCE_INTERVAL,
        POSTPONE_METHOD.DEBOUNCE,
      )
    }

    this.store.dispatch(fetchProjectsForAllFederationServers())
    this.renderConnected(elementOrId, AdminProjectsSidebar, props)
  },

  renderProjectsPopup(elementOrId: HTMLElement | string, props: ProjectsPopupProps = {}) {
    const bypassSubscription = window.internalProps['teamcity.ui.sidebar.skipWSEventsSubscription']
    const handler = () => {
      this.store.dispatch(fetchOverview())
      this.store.dispatch(
        fetchProjectsWithBuildTypesData(true, props.parentProjectId, {
          includeRoot: true,
          withTemplates: props.editMode,
        }),
      )
    }

    if (bypassSubscription) {
      handler()
    } else {
      subscribeOnOverallEvents(
        getTopics('', [
          PROJECT_REMOVED,
          PROJECT_CREATED,
          PROJECT_ARCHIVED,
          PROJECT_DEARCHIVED, // TODO reenable UPDATED events after making sure that only one request is scheduled in case of multiple open tabs
          // PROJECT_UPDATED,
          // BUILD_TYPE_REGISTERED,
          // BUILD_TYPE_UNREGISTERED,
          // BUILD_TYPE_UPDATED,
        ]),
        handler,
        DEFAULT_DEBOUNCE_INTERVAL,
        POSTPONE_METHOD.DEBOUNCE,
      )
    }

    this.store.dispatch(fetchProjectsForAllFederationServers(true))
    this.renderConnected(elementOrId, ProjectsPopup, props)
  },

  renderAllBuilds(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<typeof AllBuilds, React.ComponentProps<typeof AllBuilds>>,
  ) {
    this.store.dispatch(fetchProjectsWithArchivedData())
    this.renderConnected(elementOrId, AllBuilds, props)
  },

  renderTwoFactorAuthSettings(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof TwoFactorAuthSettings>,
  ) {
    this.renderConnected(elementOrId, TwoFactorAuthSettings, props)
  },

  renderTwoFactorAuthLoginScreen(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof TwoFactorAuthLoginScreen>,
  ) {
    this.renderConnected(elementOrId, TwoFactorAuthLoginScreen, props)
  },

  renderHttpsConfiguration(
    elementOrId: HTMLElement | string,
    props: React.ComponentProps<typeof HttpsConfiguration>,
  ) {
    this.renderConnected(elementOrId, HttpsConfiguration, props)
  },

  renderBuildsTvMode(elementOrId: HTMLElement | string) {
    this.renderConnected(elementOrId, BuildsTvMode, {})
  },

  renderAgents(elementOrId: HTMLElement | string, props: AgentsOwnProps) {
    this.renderConnected(elementOrId, AgentsContainer, props)
  },

  renderAgentsFetcher(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof AgentsFetcher,
      React.ComponentProps<typeof AgentsFetcher>
    >,
  ) {
    this.renderConnected(elementOrId, AgentsFetcher, props)
  },

  renderAdminMetricsTab(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<typeof MetricsTab, React.ComponentProps<typeof MetricsTab>>,
  ) {
    this.renderConnected(elementOrId, MetricsTab, props)
  },

  renderSorter(
    elementOrId: HTMLElement | string,
    props: {
      dimensions: ReadonlyArray<string>
    },
  ) {
    this.renderConnected(elementOrId, Sorter, props)
  },

  renderPager(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<typeof Pager, React.ComponentProps<typeof Pager>>,
  ) {
    this.createAndRender(elementOrId, Pager, props)
  },

  renderTagsFilters(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof TagsFilters,
      React.ComponentProps<typeof TagsFilters>
    >,
  ) {
    this.renderConnected(elementOrId, TagsFilters, props)
  },

  renderAnimatingIcon(
    elementOrId: HTMLElement | string,
    wrapperId: string,
    props: JSX.LibraryManagedAttributes<typeof Icon, React.ComponentProps<typeof Icon>>,
  ) {
    this.render(
      <Visible TagName="span" id={wrapperId}>
        {() => <Icon {...props} />}
      </Visible>,
      elementOrId,
    )
  },

  renderApprovalWidget(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof BuildApproval,
      React.ComponentProps<typeof BuildApproval>
    >,
  ) {
    this.renderConnected(elementOrId, BuildApproval, props)
  },

  renderHealthItem(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof HeaderHealthLazy,
      React.ComponentProps<typeof HeaderHealthLazy>
    >,
  ) {
    this.renderConnected(elementOrId, HeaderHealthLazy, props)
  },

  renderShowDslButton(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<typeof ShowDsl, React.ComponentProps<typeof ShowDsl>>,
  ) {
    this.store
      .dispatch(fetchDslOptions(base_uri))
      .then(() => this.renderConnected(elementOrId, ShowDsl, props))
  },

  renderBuildTypeDslEditor(elementOrId: HTMLElement | string, props: BuildTypeDslEditorProps) {
    this.renderConnected(elementOrId, BuildTypeDslEditor, props)
  },

  renderFragmentDslEditor(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof FragmentDslEditor,
      React.ComponentProps<typeof FragmentDslEditor>
    >,
  ) {
    this.renderConnected(elementOrId, FragmentDslEditor, props)
  },

  renderCleanup(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<typeof Cleanup, React.ComponentProps<typeof Cleanup>>,
  ) {
    this.renderConnected(elementOrId, Cleanup, props)
  },

  renderProjectCleanup(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof CleanupPolicies,
      React.ComponentProps<typeof CleanupPolicies>
    >,
  ) {
    this.store.dispatch(
      fetchProjectsDataWithOptions(
        {
          withBuildTypes: true,
          includeRoot: true,
        },
        props.projectId,
      ),
    )
    this.renderConnected(elementOrId, CleanupPolicies, props)
  },

  renderHintsBoard(elementOrId: HTMLElement | string) {
    this.renderConnected(elementOrId, Hints, {})
  },

  registerHint(hint: HintType) {
    this.store.dispatch(addHint(hint))
  },

  unregisterHint(id: HintId) {
    this.store.dispatch(removeHint(id))
  },

  renderHeader(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<typeof Header, React.ComponentProps<typeof Header>>,
  ) {
    this.renderConnected(elementOrId, Header, props)
  },

  redirectToExperimentalUI,

  showBuildInvestigationHistoryPopup(
    element: HTMLElement,
    buildId: BuildId,
    buildTypeId: BuildTypeId,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(openDialog(stringifyId(buildId), DialogType.INVESTIGATION_HISTORY))
    if (!this.isExperimentalUI) {
      this.renderConnected(element, BuildTypeInvestigationHistoryPopupDummy, {
        id: buildId,
        buildTypeId,
        projectInternalId,
      })
    }
  },

  showBuildTypeInvestigationHistoryPopup(
    element: HTMLElement,
    buildTypeId: BuildTypeId,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(openDialog(stringifyId(buildTypeId), DialogType.INVESTIGATION_HISTORY))
    if (!this.isExperimentalUI) {
      this.renderConnected(element, BuildTypeInvestigationHistoryPopupDummy, {
        id: buildTypeId,
        buildTypeId,
        projectInternalId,
      })
    }
  },

  showBuildStatusWidgetPopup(
    element: HTMLElement,
    buildTypeId: BuildTypeId,
    buildTypeInternalId?: BuildTypeInternalId,
    initiallySelectedBranch?: BranchType,
  ) {
    this.store.dispatch(openDialog(stringifyId(buildTypeId), DialogType.BUILD_STATUS_WIDGET))
    this.renderConnected(element, BuildStatusWidget, {
      buildTypeId,
      buildTypeInternalId,
      initiallySelectedBranch,
      overrideCloseDialog: () => this.performCleanups(element),
    })
  },

  showTestInvestigationHistoryPopup(
    element: HTMLElement,
    projectId: ProjectId,
    testId: TestId,
    testName: string,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(openDialog(stringifyId(testId), DialogType.INVESTIGATION_HISTORY))
    if (!this.isExperimentalUI) {
      this.renderConnected(element, TestInvestigationHistoryPopupDummy, {
        projectId,
        testId,
        testName,
        projectInternalId,
      })
    }
  },

  showBuildProblemInvestigationHistoryPopup(
    element: HTMLElement,
    projectId: ProjectId,
    buildTypeId: BuildTypeId,
    buildId: BuildId,
    problemOccurrenceId: ProblemOccurrenceId,
    problemId: ProblemId,
    problemName: string,
    projectInternalId: ProjectInternalId,
  ) {
    this.store.dispatch(
      openDialog(stringifyId(problemOccurrenceId), DialogType.INVESTIGATION_HISTORY),
    )
    if (!this.isExperimentalUI) {
      this.renderConnected(element, BuildProblemInvestigationHistoryPopupDummy, {
        projectId,
        buildTypeId,
        buildId,
        problemOccurrenceId,
        problemId,
        problemName,
        projectInternalId,
      })
    }
  },

  closeEditDsl(controlId: string) {
    this.store.dispatch(hideDsl(controlId))
  },

  storeDslFragment(content: string) {
    this.store.dispatch(storeDslFragment(content))
  },

  onShow(element: HTMLElement, callback: () => unknown) {
    const unsubscribe = observeVisibilityMulticast(element, isVisible => {
      if (isVisible) {
        callback()
        unsubscribe()
      }
    })
  },

  extendServerInfo(serverInfo: ServerInfo) {
    this.store.dispatch(receiveServerInfo(serverInfo))
  },

  insertPlugin(placeId: PlaceId, name: string) {
    this.store.dispatch(addPlugin(placeId, name))
  },

  renderPluginPlace(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof PluginPlace,
      React.ComponentProps<typeof PluginPlace>
    >,
  ) {
    this.renderConnected(elementOrId, PluginPlace, props)
  },

  addMarkdownAlert(
    source: string = '',
    type?: AlertType,
    timeout?: number,
    options?: Partial<AlertItem>,
  ): AlertKey {
    const md = <Markdown>{source}</Markdown>
    return String(AlertService.addAlert(md, type, timeout, options))
  },

  removePlugin(placeId: PlaceId, name: string) {
    this.store.dispatch(removePlugin(placeId, name))
  },

  requestSWUpdate,

  renderServiceWorkerChecker(
    elementOrId: HTMLElement | string,
    props: JSX.LibraryManagedAttributes<
      typeof ServiceWorkerChecker,
      React.ComponentProps<typeof ServiceWorkerChecker>
    > &
      JSX.IntrinsicAttributes,
  ) {
    this.renderConnected(elementOrId, ServiceWorkerChecker, props)
  },

  stopObservingVisibility: stopObserving,
  showHide,
  getSandbox,
  queryToObject,
  history: browserHistory,
  AgentAuth,
  AvatarEditor,
  Avatar,

  async checkDefaultExcluded(node: ProjectOrBuildTypeNode): Promise<boolean> {
    const isDefaultPresent = await requestIsBranchPresent(
      restRoot,
      getEndpointForBranches({
        node,
        branch: defaultBranch,
      }).presence,
    )
    return !isDefaultPresent
  },
  serviceWorkers: {
    setup: setupServiceWorker,
    disable: unregisterServiceWorker,
    enable: registerServiceWorker,
  },
  checkHasBranches: (id: BuildTypeId): Promise<boolean> =>
    requestIsBranchPresent(
      restRoot,
      getEndpointForBranches({
        node: getBuildTypeFilter(id),
      }).presence,
    ),
  getColorRgbByString: (key: string | null | undefined): ColorValueArray =>
    getColorRgbByString(key),
}

declare global {
  interface Window {
    ReactUI: typeof ReactUI
  }
}
window.ReactUI = ReactUI
export default ReactUI
