import {
  getAgentHref,
  getAgentsOverviewHref,
  getChangeHref,
  getChangesHref,
  getCloudImageHref,
  getFavoriteBuildsHref,
  getOverviewHref,
  getQueueHref,
  getTestHistoryHref,
  getUnauthorizedAgentsHref,
} from '../../routes'
import {tabIsBlackListed} from '../../selectors'
import type {NodeType, TabId} from '../../types'
import {
  BuildPageTabNamesEnum,
  BuildTypePageTabNamesEnum,
  MainUIAgentTabNamesEnum,
  OverviewAgentsTabNamesEnum,
  OverviewCloudImageTabNamesEnum,
  ProjectPageTabNamesEnum,
  stringifyId,
  toTabId,
} from '../../types'
import {internalProps} from '../../types/BS_types'
import invertDictionary from '../../utils/invertDictionary'
import type {KeyValue} from '../../utils/object'
import {objectToQuery} from '../../utils/queryParams'
import {matchOldToNewTabName} from '../common/ChangeDetailsTabs/ChangeDetailsTabs.utils'
import {DependenciesTypes} from '../pages/BuildPage/DependenciesTab/DependenciesTab.modes'
import {Modes} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'

import type {OpenInExperimentalUIProps} from './ToggleExperimentalUI.types'

const USE_NEW_TEST_HISTORY_PAGE: boolean = internalProps['teamcity.ui.newTestHistoryPage']
const USE_NEW_CHANGE_PAGE: boolean = internalProps['teamcity.ui.newChangePage']
const USE_NEW_CHANGES_PAGE: boolean = internalProps['teamcity.ui.newChangesPage']
const USE_NEW_QUEUE_PAGE: boolean = internalProps['teamcity.ui.sakuraQueuePage.enabled']

export const ProjectTabs: KeyValue<string, TabId> = {
  projectOverview: ProjectPageTabNamesEnum.OVERVIEW,
  stats: ProjectPageTabNamesEnum.STATISTICS,
  testDetails: ProjectPageTabNamesEnum.TEST_DETAILS,
}
export const InvertedProjectTabs: KeyValue<TabId, string> = invertDictionary(ProjectTabs)

export const BuildTypeTabsWithoutAliases: KeyValue<TabId, string> = {
  buildTypeBranches: BuildTypePageTabNamesEnum.OVERVIEW,
  buildTypeHistoryList: BuildTypePageTabNamesEnum.OVERVIEW,
}
const BuildTypeModes: KeyValue<string, string> = {
  buildTypeBranches: Modes.BRANCHES,
  buildTypeStatusDiv: Modes.BUILDS,
}
export const BuildTypeTabs: KeyValue<string, TabId> = {
  ...BuildTypeTabsWithoutAliases,
  buildTypeStatusDiv: BuildTypePageTabNamesEnum.OVERVIEW,
  buildTypeStatistics: BuildTypePageTabNamesEnum.STATISTICS,
  pendingChangesDiv: BuildTypePageTabNamesEnum.PENDING_CHANGES,
  buildTypeChains: BuildTypePageTabNamesEnum.CHAINS,
}
export const InvertedBuildTypeTabs: KeyValue<TabId, string> = invertDictionary<string, TabId>(
  BuildTypeTabs,
)

export const BuildTabs: KeyValue<string, TabId> = {
  buildResultsDiv: BuildPageTabNamesEnum.OVERVIEW,
  queuedBuildOverviewTab: BuildPageTabNamesEnum.OVERVIEW,
  buildChangesDiv: BuildPageTabNamesEnum.CHANGES,
  dependencies: BuildPageTabNamesEnum.DEPENDENCIES,
  buildLog: BuildPageTabNamesEnum.LOG,
  testsInfo: BuildPageTabNamesEnum.TESTS,
  artifacts: BuildPageTabNamesEnum.ARTIFACTS,
}
export const InvertedBuildTabs: KeyValue<TabId, string> = invertDictionary<string, TabId>(BuildTabs)

const DepsTabs: Record<string, string> = {
  snapshot: DependenciesTypes.SNAPSHOT,
  downloadedArtifacts: DependenciesTypes.DOWNLOADED_ARTIFACTS,
  deliveredArtifacts: DependenciesTypes.DELIVERED_ARTIFACTS,
}
export const InvertedDepsTabs = invertDictionary(DepsTabs)

const AgentsTabs: KeyValue<string, TabId> = {
  unauthorizedAgents: OverviewAgentsTabNamesEnum.ALL_AGENTS,
  registeredAgents: OverviewAgentsTabNamesEnum.ALL_AGENTS,
  clouds: OverviewAgentsTabNamesEnum.ALL_AGENTS,
  agentsParametersReport: OverviewAgentsTabNamesEnum.PARAMETERS_REPORT,
  agentsStatisticsTab: OverviewAgentsTabNamesEnum.AGENTS_STATISTICS,
  'agent.push': OverviewAgentsTabNamesEnum.AGENT_PUSH,
}
export const InvertedAgentTabs: KeyValue<TabId, TabId> = invertDictionary<TabId, TabId>(
  MainUIAgentTabNamesEnum,
)

const CloudImagesTabs: KeyValue<string, TabId> = {
  agentTypeSummary: OverviewCloudImageTabNamesEnum.SUMMARY,
  agentHistory: OverviewCloudImageTabNamesEnum.HISTORY,
  agentCompatibleConfigurations: OverviewCloudImageTabNamesEnum.COMPATIBLE_CONFIGUTATIONS,
  agentBuildRunners: OverviewCloudImageTabNamesEnum.BUILD_RUNNERS,
  agentParameters: OverviewCloudImageTabNamesEnum.AGENT_PARAMETERS,
}
export const InvertedCloudImagesTabs: KeyValue<TabId, string> = invertDictionary<string, TabId>(
  CloudImagesTabs,
)

export const SWITCH_TO_SAKURA_PARAM = 'enableSakuraByDefault'

export const getExperimentalUIHref = ({
  projectId,
  buildTypeId,
  buildId,
  agentId,
  agentTypeId,
  testId,
  changeId,
  favoriteBuilds,
  agents,
  tab = '',
  depsTab,
  kind = '',
  status,
  branch,
  focusLine,
  expandAll,
  logFilter,
  tag,
  queue,
  changes,
  personal,
  enableSakuraByDefault,
}: OpenInExperimentalUIProps): string | null | undefined => {
  const tabId = toTabId(tab)

  if (tab && !changeId) {
    let nodeType: NodeType | null | undefined = null

    if (projectId) {
      nodeType = 'project'
    } else if (buildTypeId && buildId == null) {
      nodeType = 'bt'
    } else if (buildId != null) {
      nodeType = 'build'
    }

    const tabInBlackList = tabIsBlackListed(tabId, nodeType)

    if (
      tabInBlackList ||
      (projectId && !ProjectTabs[tab]) ||
      (buildTypeId && buildId == null && !BuildTypeTabs[tab]) ||
      (buildId != null && !BuildTabs[tab]) ||
      (agents === true && !AgentsTabs[tab])
    ) {
      return null
    }
  }

  if (favoriteBuilds === true) {
    return getFavoriteBuildsHref()
  }

  if (agents === true) {
    if (tab === 'unauthorizedAgents') {
      return getUnauthorizedAgentsHref()
    }

    return `${getAgentsOverviewHref()}${
      tab
        ? `?${objectToQuery({
            tab: AgentsTabs[tab],
          })}`
        : ''
    }`
  }

  if (agentId) {
    return `${getAgentHref(agentId)}${
      tab
        ? `?${objectToQuery({
            tab: stringifyId(MainUIAgentTabNamesEnum[tabId] || tabId),
            kind,
          })}`
        : ''
    }`
  }

  if (agentTypeId) {
    return `${getCloudImageHref(agentTypeId)}${
      tab
        ? `?${objectToQuery({
            tab: CloudImagesTabs[tab],
            kind,
          })}`
        : ''
    }`
  }

  if (changeId != null) {
    return USE_NEW_CHANGE_PAGE
      ? getChangeHref({
          changeId,
          buildTypeId,
          personal,
          tab: matchOldToNewTabName(tab),
        })
      : null
  }

  if (testId != null) {
    return USE_NEW_TEST_HISTORY_PAGE
      ? getTestHistoryHref(testId, {
          currentProjectId: stringifyId(projectId),
          currentBuildTypeId: buildTypeId != null ? stringifyId(buildTypeId) : null,
          branch,
          status,
        })
      : null
  }

  if (queue === true) {
    return USE_NEW_QUEUE_PAGE ? getQueueHref() : null
  }

  if (changes === true) {
    return USE_NEW_CHANGES_PAGE ? getChangesHref() : null
  }

  if (buildId != null && branch != null && /^[+-]:/.test(branch)) {
    return null
  }

  return `${getOverviewHref({
    projectId,
    buildTypeId,
    buildId,
  })}?${objectToQuery({
    branch,
    projectTab: projectId != null ? ProjectTabs[tab] : null,
    buildTypeTab: buildTypeId != null && buildId == null ? BuildTypeTabs[tab] : null,
    buildTab: buildId != null ? BuildTabs[tab] : null,
    mode: buildTypeId != null && buildId == null ? BuildTypeModes[tab] : null,
    type: depsTab != null ? DepsTabs[depsTab] : null,
    focusLine,
    expandAll,
    logFilter,
    tag,
    enableSakuraByDefault,
  })}`
}
export function redirectToExperimentalUI(props: OpenInExperimentalUIProps): boolean {
  const href = getExperimentalUIHref(props)

  if (href == null) {
    return false
  }

  window.location.replace(href)
  return true
}
