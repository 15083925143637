import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'

import {createPortal} from 'react-dom'
import {useDispatch, useSelector} from 'react-redux'

import {setUserProperty} from '../../actions'
import {getBooleanUserProperty, getCurrentUserLoaded, getUserProperty} from '../../selectors'
import {UserProperties} from '../../utils/userProperties'

import version from '../../utils/version'
import Link from '../common/Link/Link'
import SvgIcon from '../common/SvgIcon/SvgIcon'

import {useSakuraReleaseBanner} from './SakuraRelease.hooks'
import {SakuraReleasePopup} from './SakuraRelease.popup'

import styles from './SakuraRelease.css'

const feedbackURL = `https://teamcity-support.jetbrains.com/hc/en-us/requests/new?ticket_form_id=360001686659&tcv=${version}`

export type SakuraReleaseBannerPropsType = {
  readonly href: string
}

export const SakuraReleaseBanner = React.memo(({href}: SakuraReleaseBannerPropsType) => {
  const portal = document.getElementById('sakura-release-banner-portal')
  const dispatch = useDispatch()
  const [show, setShowMore] = React.useState(false)
  const onShow = React.useCallback(() => setShowMore(true), [])
  const onHide = React.useCallback(() => setShowMore(false), [])

  const showReleaseBanner = useSakuraReleaseBanner()
  const isCurrentUserLoaded = useSelector(getCurrentUserLoaded)

  const hasSeenBannerBefore = useSelector(state =>
    isCurrentUserLoaded
      ? getBooleanUserProperty(state, UserProperties.HAS_SEEN_SAKURA_RELEASE_POPUP)
      : true,
  )

  const hasSeenSakuraUIAfterRelease = useSelector(state =>
    isCurrentUserLoaded ? getUserProperty(state, UserProperties.LAST_SEEN_SAKURA_UI_VERSION) : true,
  )

  React.useEffect(() => {
    if (!hasSeenBannerBefore && !hasSeenSakuraUIAfterRelease) {
      onShow()
      dispatch(setUserProperty(UserProperties.HAS_SEEN_SAKURA_RELEASE_POPUP, 'true'))
    }
  }, [dispatch, hasSeenBannerBefore, hasSeenSakuraUIAfterRelease, onShow])

  const node = (
    <React.Suspense fallback={<div data-suspense-fallback className={styles.placeholder} />}>
      <div className={styles.wrapper}>
        <span className={styles.description}>
          {'The Sakura UI is here: a fresh look, improved usability, and new features.'}
        </span>
        <div className={styles.controls}>
          <Button primary className={styles.more} onClick={onShow}>
            {'Read more'}
          </Button>
          <Button className={styles.switch} href={href}>
            {'Switch now'}
          </Button>
        </div>
        <Link className={styles.feedback} href={feedbackURL} target={'_blank'} rel="noreferrer">
          {WrapText => (
            <>
              <SvgIcon className={styles.feedbackIcon} icon={'comment'} />
              <WrapText>{'Share feedback...'}</WrapText>
            </>
          )}
        </Link>
      </div>
      <SakuraReleasePopup show={show} onCloseAttempt={onHide} href={href} />
    </React.Suspense>
  )

  return portal && showReleaseBanner ? createPortal(node, portal) : null
})
SakuraReleaseBanner.displayName = 'SakuraReleaseBanner'
export default SakuraReleaseBanner
